@import "../../../scss/variables";
@import "../../../scss/mixin";

section.about_landing {
    background-color: $primary;
    overflow: hidden;

    .content_wrap {

        .content_slide {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
            }

            .left_text {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                position: relative;
                z-index: 3;
                padding-bottom: 100px;

                @media only screen and (max-width:$tab) {
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 24px;
                    order: 1;
                }

                h1 {
                    transform: translateX(56px);

                    @media only screen and (max-width:$tab) {
                        transform: translateX(-32px);
                        text-align: center;
                    }
                }
            }

            .right_text {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                position: relative;
                z-index: 0;
                padding-bottom: 24px;

                @media only screen and (max-width:$tab) {
                    justify-content: center;
                    align-items: center;
                    padding-bottom: 0px;
                    order: 3;
                }

                h1 {
                    transform: translateX(-56px);

                    @media only screen and (max-width:$tab) {
                        transform: translateX(32px);
                        text-align: center;
                    }
                }
            }

            h1 {
                color: $white;
                white-space: nowrap;
                text-overflow: visible;
                margin-top: auto;
                @include font('PlusJakartaSans-Medium', 120px, 132px, 500, $white);

                @media only screen and (max-width:$laptop) {
                    @include font('PlusJakartaSans-Medium', 80px, 90px, 500, $white);
                }

                @media only screen and (max-width:$tab) {
                    @include font('PlusJakartaSans-Medium', 60px, 68px, 500, $white);
                }
            }

            .img_box {
                min-width: 400px;
                height: 60vh;
                width: 100px;
                z-index: 2;
                overflow: hidden;
                position: relative;
                margin-left: auto;
                margin-right: auto;

                @media only screen and (max-width:1500px) {
                    min-width: 450px;
                    width: 450px;
                }

                @media only screen and (max-width:$desktop) {
                    min-width: 350px;
                    width: 350px;
                }

                @media only screen and (max-width:$tab) {
                    min-width: 300px;
                    width: 300px;
                }

                @media only screen and (max-width:$tab) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                img {
                    width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                    animation-name: moveAnim;
                    animation-duration: 10s;
                    animation-iteration-count: infinite;
                }
            }
        }

        .bottom_line {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 24px;
            margin-top: 56px;

            @media only screen and (max-width:$mobile) {
                grid-template-columns: 1fr;
            }

            .des {
                color: $white;

                p,
                a {
                    margin: 0px;
                    @include font('PlusJakartaSans-Medium', 34px, 40px, 500, $white);

                    @media only screen and (max-width:$desktop) {
                        @include font('PlusJakartaSans-Medium', 30px, 34px, 500, $white);
                    }

                    @media only screen and (max-width:$tab) {
                        @include font('PlusJakartaSans-Medium', 26px, 30px, 500, $white);
                    }

                    @media only screen and (max-width:$mobile) {
                        @include font('PlusJakartaSans-Regular', 22px, 26px, 400, $white);
                    }
                }

                a {
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;

                @media only screen and (max-width:$mobile) {
                    justify-content: center;

                }

                button {
                    background-color: transparent;
                    border-style: none;
                    @include font('PlusJakartaSans-Medium', 16px, 20px, 400, $white);

                    svg {
                        font-size: 22px;
                    }
                }
            }
        }
    }


    @keyframes moveAnim {
        0% {
            transform: translateY(-32px);
        }

        50% {
            transform: translateY(32px);
        }

        100% {
            transform: translateY(-32px);
        }
    }
}