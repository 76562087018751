@import "../../scss/variables";
@import "../../scss/mixin";

button.square_btn {
    padding: 12px 16px;
    border-radius:8px;
    border: 1px solid $primary;
    background-color: $white;
    gap:8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    @include font('PlusJakartaSans-Medium', 16px, 20px, 400, $primary);

    &:disabled{
        opacity:0.6;
        pointer-events: none;
    }  

    svg {
        font-size: 22px;
        color: $primary;
        position: relative;
    }

    span {
        &.title {
            position: relative;
            z-index: 4;
            color: $white;
            transition: all 0.2s ease-in-out;
        }

        &.dark {
            position: absolute;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            left: -10px;
            height: 1000px;
            width: 1000px;
            border-radius:0%;
            background-color: $primary;
            z-index: 2;
            transition: all 0.2s ease-in-out;
        }

        &.light {
            position: absolute;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            height: 8px;
            width: 8px;
            left: 20px;
            border-radius: 50%;
            border-radius: 24px;
            background-color: $white;
            z-index: 4;
            transition: all 0.2s ease-in-out;
        }
    }

    &:hover {

        span {
            &.title {
                color: $primary;
            }

            &.dark {
                height: 0px;
                width: 0px;
                left: 20px;
                border-radius: 24px;
                border-radius: 50%;
            }

            &.light {
                height: 0px;
                width: 0px;
            }
        }
    }
    &.light{
        border:1px solid $white;
    }

    &.rotate{
svg{
    transform: rotate(180deg);
}
    }
  
    
}