@import "../../../scss/variables";
@import "../../../scss/mixin";

section.home_landing {


    .content_wrap {
        display: flex;
        justify-content: center;
        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 1000px;
            gap: 48px;
            min-height: 100vh;
            padding-top:120px;

            @media only screen and (max-width:$laptop) {
                gap: 32px;
            }

            @media only screen and (max-width:$tab) {
                gap: 24px;                
            }

            @media only screen and (max-width:$mobile) {
                gap: 16px;
            }

            h1 {
                text-align: center;
                word-spacing: 6px;
                @include font('PlusJakartaSans-Medium', 80px, 90px, 600, $primary);

                @media only screen and (max-width:$laptop) {
                    @include font('PlusJakartaSans-Medium', 60px, 64px, 600, $primary);
                }

                @media only screen and (max-width:$tab) {
                    @include font('PlusJakartaSans-Medium', 48px, 56px, 600, $primary);
                }

                @media only screen and (max-width:$mobile) {
                    @include font('PlusJakartaSans-Medium', 40px, 46px, 600, $primary);
                }

            }

            p {
                text-align: center;
                max-width: 800px;
                width: 100%;
            }

        }

    }
}