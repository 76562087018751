@import "../../scss/variables";


.loader_wrap{
    padding:24px;
    min-height:80vh;
    width:100%;
    display:grid;
    place-items: center;

}