@import "../../scss/variables";

.sec_title {
    display: flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:56px;


    @media only screen and (max-width:$tab) {
        flex-direction: column;
        align-items: flex-start;
        gap:8px;
        margin-bottom:48px;
    }

    .title_side {
        width: 200px;
    }

    .right {
        width: Calc(100% - 216px);

        @media only screen and (max-width:$tab) {
            width: 100%;
        }

       
    }

    &.dark{
        h2{
            color:$white;
        }
    }

}