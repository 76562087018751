// Colors
$primary: #222222;
$secondary: #6d6d6d;
$english:#e95e07;
$cloud:#abc2eb;
$grey:#a1a4a7;
$white:#f9f9f9;
$border:#808082;

$header:#60606026;

// $primary: #010205;


// Break Points
$desktop: 1200px;
$laptop: 992px;
$tab: 812px;
$mobile: 479px;
$menubreak:800px;