@import "../../scss/variables";

footer {
    overflow: hidden;

    section.footer {
        .content_wrap {
            border-top: 1px solid $border;
            padding-top: 48px;

            .grid_line {
                display: grid;
                grid-template-columns:1fr 2fr 2fr;
                gap: 24px;
                @media only screen and (max-width:1150px){
                    grid-template-columns:1fr;
                    gap:32px;
                }

               
                .info {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 16px;
                    padding-right: 100px;

                    @media only screen and (max-width:1350px) {
                        padding-right: 0px;
                    }

                    img {
                        max-height: 80px;
                    }

                    p {
                        color: $primary;
                    }

                
                }

                .link_grid{
                    display:grid;
                    grid-template-columns: 1fr 1fr;
                    gap:24px;
                    @media only screen and (max-width:$mobile){
                        grid-template-columns: 1fr;
                    }

                }
                .link_col {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap:8px;


                    &.address{
                     
                        p{
                            color: $primary;
                        }
                    }

                    a {
                        color: $primary;
                        transition: all 0.3s ease-in-out;

                        &:hover,
                        &.active {
                            font-weight: bold;
                        }

                        display:flex;
                        justify-content:flex-start;
                        align-items: center;
                        gap:8px;

                        svg {
                            font-size: 16px;
                        }
                    }
                }
                .iframe_wrap{
                    display:flex;
                    justify-content: center;
                 
                    iframe{  
                        display:flex;
                        flex-direction:column;
                        justify-content: flex-start;
                        padding-top:0px;
                        max-width:100%!important;
                    }
                }
            }

            .bottom_footer {
                border-top: 1px solid $border;
                padding-top: 32px;
                padding-bottom: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 32px;

                @media only screen and (max-width:$tab) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                }

                p {
                    color: $primary;
                }
            }
        }
    }

  
}