@import "../../../scss/variables";

section.expertise {   
    transition: all 0.4s ease-in-out;

    .content_wrap {

        .info_grid {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width:$desktop) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 16px;
            }

            .title_wrap {
                width: 200px;

                @media only screen and (max-width:$desktop) {
                    width: 100%;
                }

                p {
                    color: $white;
                }

                .circle_wrap {
                    span {
                        border: 1px solid $white;
                    }
                }
            }

            .content_wraper {
                width: calc(100% - 226px);

                @media only screen and (max-width:$desktop) {
                    width: 100%;
                }

                .title_right {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding-bottom: 56px;


                    @media only screen and (max-width:$laptop) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 24px;
                    }

                    h2 {
                        width: calc(100% - 280px);
                        color: $white;

                        @media only screen and (max-width:$laptop) {
                            width: 100%;
                        }
                    }

                    .sub_title {
                        width: 256px;

                        @media only screen and (max-width:$laptop) {
                            width: 100%;
                        }

                        p {
                            color: $white;
                            margin-bottom: 16px;

                            @media only screen and (max-width:$laptop) {
                                margin-bottom: 24px;
                            }
                        }
                    }

                }

                .list_card {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;
                    border-top: 1px solid $white;
                    padding: 24px 0px;

                    @media only screen and (max-width:$tab) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 16px;
                    }

                    &:last-child {
                        border-bottom: 1px solid $white;
                    }

                    h1 {
                        color: $white;
                        transition: all 0.3s ease-in-out;

                        p{
                            color:$white;
                            margin-top:16px;
                            max-width:600px;
                            width:100%;
                        }
                    }
                    

                    .img_wrap {
                        height: 150px;
                        border-radius: 8px;
                        overflow: hidden;
                        width: 0px;
                        transition: all 0.3s ease-in-out;
                      
                        @media only screen and (max-width:$tab) {
                            width: 250px;
                            height: 250px;
                        }

                        @media only screen and (max-width:$mobile) {
                            width: 100%;
                            height: auto;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .text_content{
                        ul{
                            margin-top:8px;
                            color:$white;
                            li{
                                color:$white;
                            }
                        }
                    }

                    &:hover {
                        .img_wrap {
                            width: 150px;
                            display: inline-block;
                            @media only screen and (max-width:$tab) {
                                width: 250px;
                                height: 250px;
                            }

                            @media only screen and (max-width:$mobile) {
                                width: 100%;
                                height: auto;
                            }
                        }
                  
                        .text_content{
                            width:Calc(100% - 172px);
                        }

                    }
                }
            }
        }




    }

    &.dark {
        background-color: $primary;
    }

    &.light {
        background-color: $white;

        .content_wrap {
            .info_grid {
                .title_wrap {
                    p {
                        color: $primary;
                    }

                    .circle_wrap {
                        span {
                            border: 1px solid $primary;
                        }
                    }
                }

                .content_wraper {
                    .title_right {
                        h2 {
                            color: $primary;
                        }

                        .sub_title {
                            p {
                                color: $primary;
                            }
                        }
                    }

                    .list_card {
                        border-top: 1px solid $primary;

                        h1 {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}