@import "../../scss/variables";
@import "../../scss/mixin";

.pre_loader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 999999;
    background-color: $white;
    overflow: hidden;
    position: relative;
    z-index: 0;

    .loader_content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 60px;
            object-fit: contain;
        }
.name{
    display:flex;
    justify-content: center;
    align-items: center;
}
        h2 {
            text-align: center;
            @include font('Satoshi-Bold', 24px, 48px, 600, #000000);
        }
    }

    .box {
        background-color: transparent;
        padding-right: 100%;
        animation-name: animBg;
        animation-duration: 1s;
        border: 1px solid rgba($english, 0.05);
        animation-fill-mode: forwards;
        background-color: $primary;
        position: relative;
        z-index: 4;

        &.c {
            border-style: none;
        }
    }
}

@keyframes animBg {
    from {
        background-color: $primary;
    }

    to {
        background-color: transparent;
    }
}