@import "../../scss/variables";
@import "../../scss/mixin";

.error_page{
    h1{
        text-align: center;
        color:$primary;
        transition: 0.3s ease-in-out;
        @media only screen and (max-width:600px){
            @include font('PlusJakartaSans-Medium', 26px, 30px, 500, $primary); 
        }
        @media only screen and (max-width:420px){
            @include font('PlusJakartaSans-Medium', 20px, 24px, 500, $primary);
        }
        @media only screen and (max-width:320px){
            @include font('PlusJakartaSans-Medium', 16px, 20px, 500, $primary);
        }
        span{
                   
            animation-name: animText;
            animation-duration:10s;
            animation-iteration-count: infinite;
            &:nth-child(2n){
                animation-delay:1s;
            }
            &:nth-child(3n){
                animation-delay:2s;
            }
            
        }
    }
}

@keyframes animText{
    0% {
        color:rgba($secondary, 0);  

    }50%{
        color:rgba($secondary, 0.5);  
    }100%{
        color:rgba($secondary, 0);  
    }
}