@import "../../../scss/variables";

section.faq {
    .content_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 80px;

        @media only screen and (max-width:$desktop) {
            gap: 32px;
        }

        @media only screen and (max-width:$tab) {
            grid-template-columns: 1fr;
            gap: 48px;
        }

        .left_title {
            align-self: flex-start;
            position:sticky;
            top:130px;
            overflow: hidden;
            @media only screen and (max-width:$tab) {
                position:relative;
                top:0px;
            }
            h2 {
                margin-bottom: 24px;
            }

            .btn_line {
                margin-top: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
            }
        }

        .faq_accord {
            .accordion {
                border-bottom:1px solid $border;
                .accordion-item {
                    border-style: none;
                    border-top: 1px solid $border;
                    border-radius: 0px;
                    padding: 24px 0px;

                    @media only screen and (max-width:$tab) {
                        padding: 16px 0px;
                    }

                    .accordion-header {
                        border-style: none;

                        button {
                            background-color: transparent;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 8px;

                            h5 {
                                width: calc(100% - 32px);
                            }

                            .circle {
                                height: 28px;
                                width: 28px;
                                border-radius: 50%;
                                background-color: $white;
                                display: grid;
                                place-items: center;
                                border: 1px solid $primary;
                            }


                            &::after {
                                display: none;
                            }

                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }

                        button[aria-expanded="true"] {
                            span.open {
                                display: none;
                            }

                            span.close {
                                display: block;
                            }
                        }

                        button[aria-expanded="false"] {
                            span.open {
                                display: block;
                            }

                            span.close {
                                display: none;
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                          .desc_text{
                            a{
                                color:$primary;
                            }
                          }  
                        }
                    }
                }
            }
        }
    }
}