@import "../../../scss/variables";
@import "../../../scss/mixin";

section.contact_sec {
    .content_wrap {
        .captcha_wrap {
            margin: 32px 0px;
        }

        .title_wrap {
            margin-bottom: 56px;

            p {
                margin-top: 16px;
            }
        }

        .form_line {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            margin-bottom: 32px;
        }

        .category_wrap {
            margin-top: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            margin-bottom: 32px;

            button {
                background-color: rgba($border, 0.2);
                border-style: none;
                padding: 16px 20px;
                border-radius: 24px;
                color: $primary;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: rgba($border, 0.5);
                    transform: translateY(-8px);
                }

                &.active {
                    background-color: rgba($border, 0.5);
                }
            }
        }

        input,
        textarea {
            width: 300px;
            border-style: none;
            background-color: transparent;
            padding: 8px 16px;
            border-radius: 4px;
            border-bottom: 1px solid $border;
            @include font('PlusJakartaSans-Medium', 18px, 24px, 400, $primary);
        }

        textarea {
            max-width: 500px;
            width: 100%;
        }

        p.error {
            color: red;
        }

        .mandetory_field {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        .success_screen {
            max-width: 500px;
            width: 100%;
            border: 1px solid rgba($primary, 0.2);
            padding: 32px;
            border-radius: 16px;
            position: relative;

            p {
                margin-top: 8px;
            }

            button.close {
                position: absolute;
                top: 16px;
                right: 16px;
                border: 1px solid rgba($primary, 0.2);
                background-color: transparent;
                display: grid;
                place-items: center;
                padding: 4px;
                border-radius: 2px;
                transition: all 0.3s ease-in-out;

                svg {
                    font-size: 20px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    background-color: $primary;

                    svg {
                        color: $white;
                    }
                }

            }
        }
    }
}