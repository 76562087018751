@import "../../../scss/variables";

section.case_studies {
    overflow: hidden;

    .content_wrap {
        .title_wrap {
            margin-bottom: 56px;
            max-width:350px;
            width:100%;
        }
    }
}

@keyframes oillAnim {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-16px);
    }
    100%{
        transform: translateY(0px);
    }
}