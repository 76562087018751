@import "../../scss/variables.scss";

section.blog_sec {
  .content_wrap {
    .title_wrap {
      width: 50%;
      padding-bottom: 48px;

      @media only screen and (max-width: $laptop) {
        width: 100%;
      }

      h1 {
        margin-bottom: 16px;
      }
    }
    .blog_list {
      border-top: 1px solid $border;
      display: flex;
      flex-direction: column;
      gap: 0px;
      .blog_card {
        padding: 24px 0px;
        border-top: 1px solid rgba($border, 0.4);
        display: grid;
        grid-template-columns: 1fr 2fr 56px;
        gap: 48px;
        @media only screen and (max-width: $laptop) {
          grid-template-columns: 1fr 2fr 48px;
          gap: 24px;
        }
        @media only screen and (max-width: $tab) {
          grid-template-columns: 1fr;
          gap: 16px;
        }

        &:nth-child(1) {
          border-style: none;
        }

        .img_wrap {
          img {
            width: 100%;
            object-fit: contain;
            border-radius: 8px;
          }
        }
        .text_grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 48px;
          align-self: flex-start;
          height: 100%;
          @media only screen and (max-width: $laptop) {
            gap: 24px;
          }
          @media only screen and (max-width: $tab) {
            gap: 24px;
            grid-template-columns: 1fr;
            gap: 8px;
          }
          h5 {
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            overflow: hidden;
            align-self: flex-start;
            @media only screen and (max-width: $mobile) {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          p {
            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            overflow: hidden;
            align-self: flex-start;
            @media only screen and (max-width: $mobile) {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
        button.arrow_btn {
          align-self: flex-start;
          height: 32px;
          width: 32px;
          border-radius: 4px;
          background-color: $primary;
          display: grid;
          place-items: center;
          border-style: none;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          @media only screen and (max-width: $tab) {
            display: none;
          }
          svg {
            font-size: 20px;
            color: $white;
          }
        }
        button.read_more {
          display: none;
          padding: 16px;
          background-color: $primary;
          color: $white;
          border-radius: 8px;
          width: 120px;
          border-style: none;
          @media only screen and (max-width: $tab) {
            display: inline-block;
          }
        }
        &:hover {
          button {
            opacity: 1;
          }
        }
      }
    }
  }
}
