@import "../../../scss/variables";

section.center_text {
    background-color: $primary;
    position:relative;
    .content_wrap {
        display: grid;
        place-items: center;
        padding-bottom:100px;

        h4 {
            max-width: 700px;
            color:$white;
            width: 100%;
            text-align: center;
        }
    }
    .pill_sec{
        height:300px;
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        overflow: hidden;
        width:100%;
        @media only screen and (max-width:950px){
            display:none;
        }
        p{
            padding:16px 24px;
            border-radius:24px;
            border:1px solid $white;
            color:$primary;
            display:inline-block;
            position:absolute;            
            left:50%;
            background-color: $white;
            
        }
    }

    .laptop_pill_sec{
        display:none;
        height:300px;
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        overflow: hidden;
        width:100%;
        @media only screen and (max-width:950px){
            display:block;
        }
        @media only screen and (max-width:$mobile){
            display:none;
        }
        p{
            padding:12px 16px;
            border-radius:24px;
            border:1px solid $white;
            color:$primary;
            display:inline-block;
            position:absolute;            
            left:50%;
            background-color: $white;
            
        }
    }

    .mobile_pill_sec{
        display:none;
        height:300px;
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        overflow: hidden;
        width:100%;
      display:none;
        @media only screen and (max-width:$mobile){
            display:block;
        }
        p{
            padding:12px 16px;
            border-radius:24px;
            border:1px solid $white;
            color:$primary;
            display:inline-block;
            position:absolute;            
            left:50%;
            background-color: $white;
            
        }
    }
}