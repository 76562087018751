@import "../../../scss/variables";

section.cta {
    overflow: hidden;
    .content_wrap {

        .home_cta_wrap {
            padding: 100px 50px;
            position: relative;
            overflow: hidden;
            border-radius: 24px;

            @media only screen and (max-width:$tab){
                padding:60px 20px;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
            }

            .overlay {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: rgba($primary, 0.454);
            }

            .cta_content {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 2;

                .dot_line {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 10px;
                    flex-wrap: wrap;

                    .circle_wrap {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        span {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            border: 1px solid $white;

                            &.dark {
                                background-color: $white;
                            }

                        }
                    }

                    p {
                        color: $white;
                        text-align: center;
                    }
                }

                h2 {
                    margin-bottom: 32px;
                    text-align: center;
                    color: $white;
                }

                p.detail {
                    max-width: 450px;
                    width: 100%;
                    color: $white;
                    text-align: center;
                    margin-bottom:24px;
                }

                .btn_line {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    margin-top: 32px;
                    flex-wrap: wrap;
                }

            }
        }
    }
}