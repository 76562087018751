@import "../../../scss/variables";
@import "../../../scss/mixin";

.tag_line {
    margin: 100px 0px 0px;
    position: relative;
    padding-bottom: 0px;
    border-top:1px solid $border;
    padding-top:24px;

    @media only screen and (max-width:$tab) {
        margin: 60px 0px 0px;
    }

    .text_slide {
        padding: 16px 20px;
    }

    h1 {

        text-align: center;
        @include font('PlusJakartaSans-Bold', 90px, 100px, 800, $primary);

        @media only screen and (max-width:$tab) {
            @include font('PlusJakartaSans-Bold', 56px, 64px, 800, $primary);
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 200px;
        background-image: linear-gradient(to right, #ffffff, rgba(249, 249, 249, 0));
        z-index: 2;
        @media only screen and (max-width:$tab){
            width: 150px;
        }
        @media only screen and (max-width:$mobile){
            width:60px;
        }
    }

    &:before {
        content: "";
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 200px;
        background-image: linear-gradient(to left, #ffffff, rgba(249, 249, 249, 0));
        z-index: 2;
        @media only screen and (max-width:$tab){
            width: 150px;
        }
        @media only screen and (max-width:$mobile){
            width:60px;
        }
    }
}