@import "../../../scss/variables";

section.logo_sec {
    background-color: $primary;

    .content_wrap {
        position: relative;

        .dot_title {
            .circle_wrap {
                span {
                    border: 1px solid $white;
                }

            }

            p {
                color: $white;
            }
        }

        .logo_slide {
            padding: 0px 8px;

         

            .logo_wraper {
                height: 200px;
                width: 200px;
                background-color: rgba(255, 255, 255, 0.084);
                border-radius: 8px;
                display:flex;
                justify-content:center;
                align-items: center;
                transition: all 0.3s ease-in-out;

                @media only screen and (max-width:$tab) {
                    height: 172px;
                    width: 172px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.12);
                }
            }
        }


    }

}