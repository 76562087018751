@import "../../../scss/variables";

section.case_section {
    overflow: hidden;
    .content_wrap {
        .bi_grid {

            display: grid;
            grid-template-columns: 1fr 0.7fr;
            gap: 48px;

            @media only screen and (max-width:$laptop) {
                grid-template-columns: 1fr 0.6fr;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                padding-top: 32px;
            }

            .name {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h4 {
                    margin-top: 16px;
                    max-width: 600px;
                    width: 100%;
                }
            }

            .img_wrap {
                max-height: 350px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                max-width: 100%;

                @media only screen and (max-width:$tab) {
                    max-height: unset;
                    width: 100%;

                }

                img {
                    height: 100%;
                    border-radius:24px;

                    @media only screen and (max-width:$tab) {
                        width: 100%;
                    }
                }
            }
        }

        .btns_wrap {
            margin-top: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;

            .left {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                flex-wrap: wrap;

                button.scroll {
                    padding: 8px 16px;
                    border-style: none;
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;


                    .spine {
                        height: 24px;
                        width: 24px;
                        border-radius: 50%;
                        border-left: 1px solid $primary;
                        border-right: 1px solid $primary;
                        border-top: 1px solid $primary;
                        border-bottom: 1px solid $white;
                        animation-name: spineAnim;
                        animation-duration: 1s;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;

                    }

                    p {
                        color: $primary;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;
                flex-wrap: wrap;

                @media only screen and (max-width:$mobile) {
                    justify-content: flex-start;
                }

                .pill {
                    padding: 8px 16px;
                    border: 1px solid $secondary;
                    border-radius: 24px;

                    p {
                        color: $primary;
                    }
                }
            }
        }

        .social_line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 48px;
            flex-wrap: wrap;
            gap: 16px;

            button.redirect {
                border-style: none;
                background-color: transparent;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;

                span {
                    border: 1px solid transparent;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    transition: all 0.3s ease-in-out;

                    svg {
                        font-size: 20px;
                        color: $primary;
                    }
                }

                &:hover {
                    span {
                        border: 1px solid $primary;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;

                p {
                    color: $primary;
                }

                .icon {
                    border: 1px solid transparent;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    display: grid;
                    place-items: center;
                    transition: all 0.3s ease-in-out;

                    svg {
                        font-size: 20px;
                        color: $primary;
                    }

                    &:hover {
                        border: 1px solid $primary;
                    }
                }
            }
        }

        .sec_img {
            width: 100%;
            iframe{
                max-width:100%;
                width:100%;
                aspect-ratio: 16 / 9;    
                }
            img {
                width: 100%;
                border-radius: 24px;
            }
        }

        .brief {
            h5 {
                max-width: 1100px;
                width: 100%;
            }
        }

        .bi_img {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
            &.m_t{
                margin-top:32px;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap: 16px;
                &.m_t{
                    margin-top:16px;
                }
            }

            .img_wrap {
                img {
                    width: 100%;
                    border-radius: 24px;
                }
            }
            .u_tube{
                width:100%;
                iframe{
                max-width:100%;
                width:100%;
                aspect-ratio: 16 / 9;   
                }
            }
        }

       
    }
}

@keyframes spineAnim {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}