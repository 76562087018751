@import "../../../scss/variables";

section.who_we {
    margin-top: 60px;

    .content_wrap {
        .title_sec {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: $primary;
            border-radius: 16px 0px 16px 16px;
            padding: 56px;
            position: relative;

            @media only screen and (max-width:$tab) {
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
            }

            @media only screen and (max-width:$mobile) {
                border-radius: 16px;
                padding: 24px;
            }

            .blog {
                border-radius: 16px 16px 0px 0px;
                background-color: $primary;
                height: 60px;
                width: 120px;
                position: absolute;
                top: 0px;
                right: 0px;
                transform: translateY(-100%);

                @media only screen and (max-width:$mobile) {
                    display: none;
                }

                &:after {
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 0px;
                    background-color: $primary;
                    width: 32px;
                    height: 32px;
                    transform: translateX(-100%);
                    border-radius: 16px 0px 0px 0px;
                    z-index: 0;
                }

                &:before {
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 0px;
                    background-color: $white;
                    width: 32px;
                    height: 32px;
                    transform: translateX(-100%);
                    border-radius: 0px 0px 16px 0px;
                    z-index: 1;
                }
            }

            h3 {
                color: $white;
            }

            .dot_title {
                p {
                    color: $white;
                }

                .circle_wrap {
                    span {
                        border: 1px solid $white;
                    }
                }
            }

            .title_side {
                width: 200px;
            }

            .right {
                width: Calc(100% - 250px);

                @media only screen and (max-width:$tab) {
                    width: 100%;
                }

                .btn_line {
                    margin-top: 48px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    @media only screen and (max-width:$tab) {
                        margin-top: 32px;
                    }
                }
            }

        }

        .how_we_work {
            display: flex;
            justify-content: space-between;
            margin-top: 56px;

            @media only screen and (max-width:$tab) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 16px;
            }

            .project_card {
                width: 400px;
                position: relative;
                padding: 24px;
                border-radius: 16px;
                overflow: hidden;

                @media only screen and (max-width:$tab) {
                    width: 100%;
                }



                .count_grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 8px;

                    @media only screen and (max-width:$mobile) {
                        grid-template-columns: 1fr;
                    }
                }

                h1 {
                    color: $white;
                    position: relative;
                    z-index: 2;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;
                }

                p {
                    color: $white;
                    position: relative;
                    z-index: 2;
                    margin: 16px 0px 48px;
                }

              
            }

            .title_card {
                width: calc(100% - 416px);
                position: relative;
                padding: 24px;
                border-radius: 16px;
                overflow: hidden;

                @media only screen and (max-width:$tab) {
                    width: 100%;
                }

                img.bg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    z-index: 0;
                }

                .over_lay {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0.383);
                    backdrop-filter: blur(2px);
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    .over_lay {
                        background-color: rgba(0, 0, 0, 0.154);
                        backdrop-filter: blur(0px);
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    gap: 24px;
                    padding: 24px;

                    h2 {
                        color: $white;
                        text-align: center;
                    }

                    button {
                        background-color: $white;
                        padding: 8px 24px 8px 8px;
                        border-radius: 40px;
                        border-style: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;

                        span {
                            height: 56px;
                            width: 56px;
                            border-radius: 50%;
                            overflow: hidden;
                            display: block;
                            position: relative;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                background-position: center center;
                            }

                            svg {
                                font-size: 22px;
                                color: $white;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                transition: all 0.3s ease-in-out;
                            }

                            &:hover {
                                svg {
                                    font-size: 30px;
                                }
                            }
                        }

                        .text {
                            p {
                                transition: all 0.3s ease-in-out;
                                text-align: left;

                                &.dark {
                                    color: $primary;
                                }
                            }
                        }

                        &:hover {
                            background-color: $primary;

                            .text {
                                p {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

}