@import "../../../scss/variables";

section.work {
    .content_wrap {
        .title_wrap {
            width: 50%;
            @media only screen and (max-width:$laptop){
                width:100%;
            }

            h1 {
                margin-bottom: 16px;
            }

            .category_wrap {
                margin-top: 56px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                gap: 8px;
                margin-bottom: 60px;

                button {
                    background-color: rgba($border, 0.2);
                    border-style: none;
                    padding: 12px 16px;
                    border-radius: 24px;
                    color: $primary;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        background-color: rgba($border, 0.5);
                        transform: translateY(-8px);                        
                    }

                    &.active {
                        background-color: rgba($border, 0.5);
                    }
                }
            }
        }
    }
}