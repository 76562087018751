@import "../../scss/variables";


section.podcast_sec {
    .content_wrap {
        .main_title {

            .podcast-links {
                padding: 0px;
                margin-top: 16px;
            }

            .streaming-app-logos {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                flex-wrap: wrap;
            }

            .social_icon {
                margin-top: 16px;
                height: 48px;
                width: 48px;
                border-radius: 50%;
                border: 1px solid $secondary;
                display: grid;
                place-items: center;
                transition: all 0.3s ease-in-out;

                svg {
                    width: 32px;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    border: 1px solid $primary;

                    svg {
                        color: $primary;
                    }
                }
            }
        }

        .feed_sec {
            margin-top: 56px;
        }
    }
}