@import "../../../scss/variables";

section.team {
    .content_wrap {
        .team_grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 24px;
            row-gap: 32px;

            @media only screen and (max-width:$desktop) {
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                column-gap: 0px;
                row-gap: 24px;
            }

            .team_card {

                .img_wrap {
                    position: relative;
                    overflow: hidden;
                    height: 550px;
                    border-radius: 16px;
                    @media only screen and (max-width:$tab) {
                        width: 100%;
                        height: auto;
                    }

                    .overlay {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        background-color: rgba(0, 0, 0, 0);
                        transition: all 0.3s ease-in-out;
                    }

                    img {
                        border-radius: 16px;
                        width: 100%;
                        min-height: 100%;
                        object-fit: cover;

                        @media only screen and (max-width:$tab) {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .text_content {
                    transition: all 0.3s ease-in-out;
                    padding: 8px;
                    background-color: $white;
                    border-radius: 24px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    h3{
                        text-align: center;
                    }

                    p {
                        color: $primary;
                        text-align: center;
                        margin-top: 8px;
                    }

                    .social {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 16px;
                        margin-top: 16px;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;

                        a {
                            font-size: 24px;
                            color: $primary;
                        }
                    }
                }

                &:hover {
                    .text_content {
                        transform: translateY(-16px);
                    }

                    .social {
                        opacity: 1;
                    }

                    .img_wrap {
                        .overlay {
                           
                            background-color: rgba(0, 0, 0, 0.3);
                        }
                    }
                }

            }
        }
    }
}