@import "../../scss/variables";

.dot_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    p{
        color:$primary;
    }

    .circle_wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: 1px solid $primary;

            &.dark {
                background-color: $primary;
            }

            &.light {
                background-color: $white;
            }
        }
    }
}