@import "../../scss/variables";

header {
    position: fixed;
    top: 32px;
    width: 100%;
    z-index: 99;

    @media only screen and (max-width:$menubreak) {
        top: 16px;
    }


    section.header {
        width: 100%;
        display: flex;

        .content_wrap {
            transition: all 0.3s ease-in-out;
            position: relative;

            a.logo_left{
                margin-top:16px;   
                                       
                img{
                    width:60px;
                    @media only screen and (max-width:$tab){
                        width:48px;
                        padding-top:8px;
                    }
                }
            }

            .tab_wrap {
                padding: 8px;
                background-color: $header;
                border-radius: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                backdrop-filter:blur(5px);
                position: absolute;
                top: 0px;
                transition: all 0.3s ease-in-out;
                z-index: 99999;

                @media only screen and (max-width:$menubreak) {
                    gap: 16px;
                }

                a {
                    padding: 16px 20px;
                    background-color: $white;
                    border-radius: 50px;
                    transition: all 0.3s ease-in-out;

                    &.link {
                        @media only screen and (max-width:$menubreak) {
                            display: none;
                        }
                    }

                    &.logo_wrap {
                        padding: 0px;
                        transition: all 0.4s ease-in-out;
                        overflow: hidden;
                        height:50px;
                        width:50px;
                        display:grid;
                        place-items: center;
                        transition:all 0.2s ease-in;
                        &:hover{
                            background-color: $white;
                        }

                        img {
                            height:32px;
                            object-fit: contain;
                        }

                    }

                    &:hover, 
                    &.active {
                        background-color: $primary;
                        color: $white;

                    }
                }

                button {
                    height: 50px;
                    width: 50px;
                    background-color: $white;
                    border-radius: 50%;
                    border-style: none;
                    transition: all 0.3s ease-in-out;

                    svg {
                        font-size: 18px;
                        color: $primary;
                        transition: all 0.3s ease-in-out;
                    }

                    &:hover {
                        background-color: $primary;

                        svg {
                            color: $white;
                        }
                    }

                    &.menu {
                        display:none;
                        @media only screen and (max-width:$menubreak){
                            display:block;
                        }
                        svg {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }

    .header_dd {
        padding: 8px;
        background-color: $header;
        border-radius: 32px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        backdrop-filter: blur(5px);
        position: absolute;
        width: calc(100% - 46px);
        top: 0px;
        left: 50%;
        transform: translateX(-50%) scaleY(0);
        transform-origin: top center;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        z-index: 999;
        padding: 120px 24px 24px;
        @media only screen and (max-width:$mobile){
            padding:80px 16px 16px;
        }

        &.open {
            transform: translateX(-50%) scaleY(1);
        }

        .link_line{
            display:flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap:8px;
            flex-wrap: wrap;
        }
        a {
            padding: 16px 20px;
            background-color: $white;
            border-radius: 50px;
            transition: all 0.3s ease-in-out;




            &:hover, &.active {
                background-color: $primary;
                color: $white;
            }
        }
        h1{
            text-align: center;
            margin-top:24px;
        }

    }
}