@import "../../scss/variables";

section.privacy_policy{
    .content_wrap{
        max-width:900px;
        width:100%;
        h3{
            margin-top:24px;     
            &:nth-child(1){
                margin-top:0px;
            }       
        }
        p{
            margin-top:8px;
        }
    }
}