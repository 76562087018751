@import "../../../scss/variables";

section.case_summary {
    .content_wrap {
        .bi_grid {
            display: grid;
            grid-template-columns: 1fr 0.8fr;
            gap: 48px;

            @media only screen and (max-width:$tab) {
                grid-template-columns: 1fr;
                gap:24px;
            }

            .count_grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;

                .count {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-end;
                    gap: 8px;
                }
            }

            .right {
                p{
                    margin-bottom: 24px;
                }
            }
        }
    }
}