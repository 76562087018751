@import "../../../scss/variables";

section.blog_info {
  .content_wrap {
    .top_title {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      padding-bottom: 48px;
      border-bottom: 1px solid $border;
      @media only screen and (max-width: $tab) {
        grid-template-columns: 1fr;
        gap: 16px;
      }
      .left {
        p {
          margin-top: 16px;
        }
       
      }
      .right {
        p {
          text-align: right;
          @media only screen and (max-width: $tab) {
            text-align: left;
          }
        }
      }
    }
    .blog_detail_grid {
      display: grid;
      grid-template-columns: 356px 1fr;
      gap: 16px;
      padding-top: 48px;
      @media only screen and (max-width:$tab){
        grid-template-columns:1fr;
        gap:48px;
      }
      .email_subscription {
        padding: 24px;
        border-radius: 16px;
        border: 1px solid rgba($border, 0.5);
        align-self: flex-start;
        position: sticky;
        top: 100px;
        @media only screen and (max-width:$tab){
          position:relative;
          top:0px;
          order:1;
        }
        @media only screen and (max-width:$mobile){
          padding:16px;
        }
        h4{
          margin-bottom:8px;
        }
        p.success{
          padding:16px;
          border:1px solid rgba($primary, 0.2);
          border-radius:8px;
          padding:8px 16px;
          margin-top:16px;
        }
        form{
          width:100%;
          margin-top:16px;
            padding-top:16px;
            border-top:1px solid rgba($border,0.4);
          .field_wrap{
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            align-items:flex-start;
            gap:8px;
            width:100%;
            
            label{
              color:$primary;
            }
            input{
              padding:8px 16px;              
              border:1px solid rgba($border, 0.5);
              border-radius:8px;
              width:100%;
            }
            p.error{
              font-size:14px;
              line-height:18px;
              color: red;
            }
            button{
              padding:16px 16px;
              background-color:$primary;
              color:$white;
              border-radius:8px;
              border-style:none;
              width:100%;
            }
          }
        }
      }
      .blog_detail_info {
        .img_wrap {
          margin-bottom: 24px;
          img {
            width: 100%;
            object-fit: contain;
            border-radius: 16px;
          }
        }
        p {
          margin-top: 8px;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 16px 0px;
        }
        a {
          color: $primary;
          text-decoration: none;
        }
      }
    }
  }
}
