@import "../../../scss/variables";

section.values {
    overflow: hidden;

    .content_wrap {
        .tri_grid {
            height: 600px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 16px;
            overflow: hidden;

            @media only screen and (max-width:1200px) {
                height: 700px;
            }

            @media only screen and (max-width:900px) {
                height: 800px;
            }

            @media only screen and (max-width:$tab) {
                display: none;
            }

            .info {
                padding-right: 150px;
                align-self: flex-start;

                @media only screen and (max-width:1200px) {
                    padding-right: 32px;
                }


                p {
                    margin-top: 8px;
                }
            }

            .sec {
                display: flex;
                flex-direction: column;
                gap: 16px;


            }
        }

        .dummy_card {
            background-color: rgba($secondary, 0.1);
            padding: 24px;
            border-radius: 16px;
            height: 180px;
        }

        .value_card {
            background-color: rgba($secondary, 0.1);
            padding: 24px;
            border-radius: 16px;
            p {
                margin-top: 8px;
            }
        }

        .tab_sec {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: none;

            @media only screen and (max-width:$tab) {
                display: flex;
            }

            .h_grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 16px;
                margin-bottom: 16px;
                min-width: 900px;
                overflow: hidden;
                transform-origin: center center;

                @media only screen and (max-width:450px) {
                    min-width: 800px;
                }

                .dummy_card {
                    height: 100%;
                }

            }
        }



    }
}