@import "./mixin";
@import "./variables";

@font-face {
    font-family: 'PlusJakartaSans-Regular';
    src: url('../assets/fonts/PlusJakartaSans-Regular.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PlusJakartaSans-Medium';
    src: url('../assets/fonts/PlusJakartaSans-Medium.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PlusJakartaSans-SemiBold';
    src: url('../assets/fonts/PlusJakartaSans-SemiBold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url('../assets/fonts/PlusJakartaSans-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PlusJakartaSans-ExtraBold';
    src: url('../assets/fonts/PlusJakartaSans-ExtraBold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi-Bold';
    src: url('../assets/fonts/Satoshi-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}


h1 {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 54px, 62px, 500, $primary);
}
h2{
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 42px, 46px, 500, $primary);
}

h3 {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 34px, 40px, 500, $primary);
}

h4 {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 28px, 32px, 500, $primary);
}

h5 {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 24px, 28px, 500, $primary);
}
h6 {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 18px, 22px, 600, $primary);
}
label, input.mail{
    @include font('PlusJakartaSans-Medium', 16px, 20px, 400, $primary);
}
p, li, {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 18px, 24px, 400, $secondary);
    &.t_12{
        @include font('PlusJakartaSans-Medium', 12px, 14px, 400, $primary);
    }
    
}

a {
    margin: 0px;
    @include font('PlusJakartaSans-Medium', 14px, 18px, 400, $primary);
    text-transform: uppercase;
}

button{
    @include font('PlusJakartaSans-Medium', 14px, 14px, 400, $primary);
}

@media only screen and (max-width:$desktop) {
    h1 {
        @include font('PlusJakartaSans-Medium', 46px, 52px, 500, $primary);
    }
    h2{
        @include font('PlusJakartaSans-Medium', 36px, 40px, 500, $primary);
    }

    h3 {
        @include font('PlusJakartaSans-Medium', 30px, 34px, 500, $primary);
    }

    h4 {
        @include font('PlusJakartaSans-Medium', 24px, 30px, 500, $primary);
    }
    h5 {
        @include font('PlusJakartaSans-Medium', 22px, 26px, 500, $primary);
    }
}

@media only screen and (max-width:$tab) {
    h1 {
        @include font('PlusJakartaSans-Medium', 38px, 44px, 500, $primary);
    }
    h2{
        @include font('PlusJakartaSans-Medium', 28px, 32px, 500, $primary);
    }
    h3 {
        @include font('PlusJakartaSans-Medium', 26px, 30px, 500, $primary);
    }

    h4 {
        @include font('PlusJakartaSans-Medium', 20px, 24px, 500, $primary);
    }
    h5 {
        @include font('PlusJakartaSans-Medium', 20px, 24px, 500, $primary);
    }
    p, li{
        @include font('PlusJakartaSans-Medium', 16px, 20px, 400, $secondary);
    }
}

@media only screen and (max-width:$mobile) {
    h1 {
        @include font('PlusJakartaSans-Regular', 34px, 40px, 400, $primary);
    }
    h2{
        @include font('PlusJakartaSans-Regular', 24px, 26px, 400, $primary);
    }
    h3 {
        @include font('PlusJakartaSans-Regular', 22px, 26px, 400, $primary);
    }

    h4 {
        @include font('PlusJakartaSans-Regular', 18px, 22px, 400, $primary);
    }
    h5 {
        @include font('PlusJakartaSans-Regular', 16px, 20px, 400, $primary);
    }
}